import React from 'react'
import CreateProduct from './createProduct'

const getIDFromPathname = pathname =>
  pathname
    .split('/')
    .pop()

function ProductOfferItem(props) {
  const id = getIDFromPathname(props.location.pathname)
  return <CreateProduct {...props} id={id} />
}

export default ProductOfferItem;